import Dropzone from 'dropzone';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DocIcon from '../../../assets/img/icons/common/icon-doc.svg';

import { uploadCsv } from 'actions/adminActions/usersActions';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { loaderType } from '../../../type';

import { getAllOrgUser } from 'actions/adminActions/organisationsActions';
import classNames from 'classnames';
import InfoIcon from 'iconComponents/InfoIcon';
import { useSelector } from 'react-redux';
import NewCustomDropzon from '../../../components/Common/NewCustomDropzon';

Dropzone.autoDiscover = false;

const ImportUsers = ({ getOrganizationsAction, uploadCsvAction }) => {
  const history = useHistory();
  const [showReportModal, setShowReportModal] = useState(false);
  const [importReport, setImportReport] = useState(false);
  const csvData = [];
  const headers = [
    { label: 'name', key: 'name' },
    { label: 'email', key: 'email' },
    { label: 'password', key: 'password' },
  ];
  const [organizations, setOrganizations] = useState([]);
  const [excelFile, setExcelFile] = useState('');
  const [selectedOrg, setSelectedOrg] = useState();
  const [alert, setAlert] = useState({
    alert: false,
    alertStatus: '',
    alertMsg: '',
  });
  const { orgData } = useSelector((state) => state?.organisation);
  useEffect(() => {
    getOrganizationsAction();
  }, []);
  useEffect(() => {
    let formatOrgData =
      orgData?.length &&
      orgData.map((item) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
    setOrganizations(formatOrgData);
  }, [orgData]);
  const uploadCSV = () => {
    if (!selectedOrg) {
      setAlert({
        alert: true,
        alertStatus: 'warning',
        alertMsg: 'Please Select Organisation.',
      });
      return;
    }
    const uploadedFile = excelFile;

    const isCsvFile =
      uploadedFile.type === 'application/vnd.ms-excel' ||
      uploadedFile.type === 'text/csv' ||
      uploadedFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (isCsvFile) {
      let uploadcsv = {
        file: uploadedFile,
        orgid: selectedOrg,
      };
      uploadCsvAction(uploadcsv)
        .then((res) => {
          setImportReport(res.data);
          setShowReportModal(res.data);
          setExcelFile('');
          if (res.data.failedImports.length === 0) {
            setAlert({
              alert: true,
              alertStatus: 'success',
              alertMsg: 'Users Import Successfully.',
            });
          }
        })
        .catch((err) => {
          setAlert({
            alert: true,
            alertStatus: 'warning',
            alertMsg: err || 'Something went to wrong',
          });
        });
    } else {
      setAlert({
        alert: true,
        alertStatus: 'warning',
        alertMsg: 'Please upload valid CSV file.',
      });
    }
  };

  const closeAlert = () => {
    setAlert({
      ...alert,
      alert: false,
      alertStatus: '',
      alertMsg: '',
    });
  };
  const handleConfirmation = () => {
    history.push('/users');
  };
  const handleUploadExcel = (file) => {
    setExcelFile(file);
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 45,
    }),
  };
  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }
  return (
    <>
      {alert && alert.alertStatus === 'success' ? (
        <SweetAlert
          success
          title={alert.alertMsg}
          onConfirm={handleConfirmation}
        />
      ) : alert.alertStatus === 'warning' ? (
        <SweetAlert warning title={alert.alertMsg} onConfirm={closeAlert} />
      ) : null}
      <Container className="" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">Import Users</h5>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                      <button className="publish_form_btn" onClick={uploadCSV}>
                        Import Users
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate>
                    <Row className="">
                      <div className="col-md-7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Organisations
                          </label>
                          <Select
                            name="selectExam"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            styles={customStyles}
                            placeholder="Select"
                            options={organizations}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            onChange={(newValue) => {
                              setSelectedOrg(newValue?.value);
                            }}
                          />
                          {/* <div className="valid-feedback">Looks good!</div> */}
                        </FormGroup>
                      </div>

                      <div className="col-md-7">
                        <FormGroup className="">
                          <label className="add_course_form_label my-2 mx-0">
                            Upload Users
                          </label>

                          <NewCustomDropzon
                            name="iconImage"
                            accept="text/csv"
                            handleUpload={handleUploadExcel}
                            type="document"
                          />

                          <div className="d-flex gap-2 my-2">
                            <InfoIcon color={'#070707'} />
                            <span
                              className={classNames({
                                add_course_form_sublabel1: true,
                              })}
                            >
                              Only .csv format is supported, download sample
                              format{' '}
                              <CSVLink
                                filename="template"
                                data={csvData}
                                headers={headers}
                              >
                                <span style={{ color: 'var(--primary)' }}>
                                  here
                                </span>
                              </CSVLink>
                            </span>
                          </div>
                          <div>
                            Note: If a user was previously added and then
                            soft-deleted, please remove their entry from the CSV
                            and contact support to re-add the user.
                          </div>
                          {excelFile && (
                            <div className="add_course_form_img_preview_wrapper">
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  className="add_course_form_preview_img"
                                  src={DocIcon}
                                  alt=""
                                />

                                <div className="d-flex flex-column">
                                  <span className="add_course_form_img_preview_name">
                                    {excelFile?.name}
                                  </span>
                                  <span className="add_course_form_img_preview_type">
                                    {excelFile.name.split('.')[1] === 'csv' &&
                                      'CSV'}
                                  </span>
                                </div>
                              </div>

                              <span className="add_course_form_img_preview_size">
                                {excelFile?.size &&
                                  bytesToSize(excelFile?.size)}
                              </span>
                            </div>
                          )}
                        </FormGroup>

                        {/* <label style={{ fontSize: 12, fontStyle: "italic" }}>
                          Only .csv format is supported, download sample format
                          -
                          <CSVLink
                            filename="template"
                            data={csvData}
                            headers={headers}
                          >
                            here
                          </CSVLink>
                        </label> */}
                      </div>
                    </Row>
                    {/* <Button
                        color="primary"
                        type="button"
                        onClick={this.uploadCSV}
                      >
                        Import Users
                      </Button> */}
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      {importReport.failedImports?.length && (
        <Modal
          isOpen={showReportModal}
          toggle={() => setShowReportModal(false)}
          size="lg"
        >
          <ModalHeader
            toggle={() => setShowReportModal(false)}
            // className="bg-primary text-white"
          >
            <h4 className="mb-0">Import Report</h4>
          </ModalHeader>
          <ModalBody>
            {importReport && (
              <div className="p-3">
                {/* Failed Imports Table */}
                {importReport.failedImports.length > 0 && (
                  <>
                    {/* <h5 className="text-danger mt-3">Failed Imports</h5> */}
                    <div
                      className="table-responsive"
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                    >
                      <Table striped bordered>
                        <thead className="bg-light">
                          <tr>
                            <th className="font-weight-bold">Name</th>
                            <th className="font-weight-bold">Email</th>
                            <th className="font-weight-bold">Error Reason</th>
                          </tr>
                        </thead>
                        <tbody>
                          {importReport.failedImports.map((user, index) => (
                            <tr key={index}>
                              <td>{user.name || 'N/A'}</td>
                              <td>{user.email || 'N/A'}</td>
                              <td>{user.reason}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    {/* Download Failed Imports Button */}
                    <div className="mt-3 text-right space-x-2">
                      <CSVLink
                        className="mr-2"
                        filename="failed_imports.csv"
                        data={importReport.failedImports}
                        headers={headers}
                      >
                        <button className="publish_form_btn">
                          Download Failed Records
                        </button>
                      </CSVLink>
                      <button
                        className="cancel_form_btn"
                        onClick={() => setShowReportModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    challenges: state.challenges,
    organizations: state.organisation.orgData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadCsvAction: (file) =>
      trackPromise(dispatch(uploadCsv(file)), loaderType.GENERAL_REQUEST),
    getOrganizationsAction: () =>
      trackPromise(dispatch(getAllOrgUser(0, 200)), loaderType.GENERAL_REQUEST),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsers);
