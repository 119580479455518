import API from "api2";

const config = (accessToken) => {
    return {
        Authorization: accessToken,
    };
};
export const createAnnouncement = (payload) => {
    return async (dispatch, getState) => {
      const token =
        getState()?.authState?.user?.accessToken ||
        localStorage.getItem('accessToken');
      return new Promise(function (resolve, reject) {
        API({
          method: 'post',
          url: `api/alert`,
          headers: config(token),
          data: payload,
        })
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            let errData =
              (err.response && err.response.data) ||
              // dispatch({ type: "CREATE_USER_ERROR" }, err);
              reject(errData);
          });
      });
    }
}
export const UpdateAnnouncement = (payload, id) => {
    return async (dispatch, getState) => {
      const token =
        getState()?.authState?.user?.accessToken ||
        localStorage.getItem('accessToken');
      return new Promise(function (resolve, reject) {
        API({
          method: 'put',
          url: `api/alert/${id}`,
          headers: config(token),
          data: payload,
        })
          .then((res) => {
            resolve(res?.data?.data);
          })
          .catch((err) => {
            let errData =
              (err.response && err.response.data) ||
              // dispatch({ type: "CREATE_USER_ERROR" }, err);
              reject(errData);
          });
      });
    }
}
export const getAnnouncement = (id) => {
    return async (dispatch, getState) => {
      const token =
        getState()?.authState?.user?.accessToken ||
        localStorage.getItem('accessToken');
      return new Promise(function (resolve, reject) {
        API({
          method: 'get',
          url: `api/alert/${id}`,
          headers: config(token),
          // data: payload,
        })
          .then((res) => {
            dispatch({
              type: 'GET_ANNOUNCEMENT_SUCCESS',
              payload: true,
              data: res.data.data,
            });
            resolve(res?.data?.data);
          })
          .catch((err) => {
            let errData =
              (err.response && err.response.data) ||
              dispatch({
                type: 'GET_ANNOUNCEMENT_FAILED',
                payload: true,
                data: err,
              });
            reject(errData);
          });
      });
    }
}