/** @format */

import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  Modal,
} from "reactstrap";
import classNames from "classnames";

const ModalLicenseMulti = ({
  open,
  toggleVisible,
  licenseId,
  setLicenseId,
  plans,
  handleUpdateLicense,
}) => {
  const [planList, setPlanList] = useState();
  const renderLicense = () => {
    return plans.map((x, index) => {
      return {
        id: index,
        value: x._id,
        text: x.name,
      };
    });
  };
  useEffect(() => {
    const plansArray = plans.map((x) => {
      return {
        label: x?.name,
        value: x?._id,
      };
    });
    setPlanList(plansArray);
  }, [plans]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={open}
      toggle={toggleVisible}
    >
      <div className="modal-body p-0">
        <Card className="bg-white border-0 mb-0">
          <CardBody className="px-4">
            <div className="text-center text-muted mb-4">
              <span>Add / Update the license ID for selected users</span>
            </div>
            <Form role="form">
              <Select
                defaultValue={licenseId}
                value={licenseId}
                isMulti
                styles={{
                  control: (styles) => ({
                    ...styles,
                    minHeight: '45px',
                    border: '1px solid #d2d6da',
                  }),
                }}
                // onSelect={(e) => setLicenseId(`${e.target.value}`)}
                // onUnselect={(e) => setLicenseId(`${e.target.value}`)}
                onChange={(newValue) => {
                  setLicenseId(newValue);
                }}
                options={planList}
              />
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleUpdateLicense}
                >
                  Update
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export default ModalLicenseMulti;
