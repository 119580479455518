import { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import DocIcon from '../../assets/img/icons/common/icon-doc.svg';
import IconFile from '../../assets/img/icons/common/icon-img.svg';
import VideoIcon from '../../assets/img/icons/common/icon-video.svg';

const NewCustomDropzon = ({ name, handleUpload, type, imageType, accept }) => {
  const ref = useRef();

  // Handle file drop
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleUpload(acceptedFiles[0], name);
      }
    },
    [handleUpload, name]
  );

  // Dropzone settings
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  const handleClick = () => {
    ref.current.click();
  };

  return (
    <>
      <div
        className="add_course_form_file_input d-flex flex-column justify-content-center align-items-center position-relative"
        {...getRootProps()}
      >
        {type === 'img' && (
          <img src={IconFile} alt="" className="add_course_form_file_icon" />
        )}
        {type === 'video' && (
          <img src={VideoIcon} alt="" className="add_course_form_file_icon" />
        )}
        {type === 'document' && (
          <img src={DocIcon} alt="" className="add_course_form_file_icon" />
        )}

        <span className="add_course_form_file_text">
          Drag and drop a file, or import from computer
        </span>

        <input
          {...getInputProps()}
          ref={ref}
          type="file"
          accept={accept}
          className="d-none"
        />
        <button
          className="add_course_form_file_submit position-absolute"
          type="button"
          onClick={handleClick}
          style={{
            top: '50%',
            left: '50%',
            marginTop: '60px',
            transform: 'translate(-50%, -50%)',
          }}
        >
          Upload {imageType}
        </button>
      </div>
    </>
  );
};

export default NewCustomDropzon;
