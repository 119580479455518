import logoutAction from 'actions/logoutAction';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 2 });

const UNAUTHORIZED = 401;

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // timeout: 10000,
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === UNAUTHORIZED) {
      try {
        await logoutAction(); // Ensure logout completes before redirection
      } catch (err) {
        console.error('Logout action failed:', err);
      }
      console.log('louout');
      window.location.href = '/login'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default apiClient;

// import logoutAction from 'actions/logoutAction';
// import axios from 'axios';
// import axiosRetry from 'axios-retry';

// axiosRetry(axios, { retries: 2 });

// const UNAUTHORIZED = 401;
// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     debugger;
//     if (error.response && error.response.status === UNAUTHORIZED) {
//       try {
//         await logoutAction(); // Ensure logout completes before redirection
//       } catch (err) {
//         console.error('Logout action failed:', err);
//       }
//       console.log('logout');
//       window.location.href = '/login'; // Redirect to login page
//     }
//     return Promise.reject(error);
//   }
// );

// export default axios.create({
//   // baseURL: "https://vcrbeta-backend.ddnsfree.com/backend1/v1/"
//   baseURL: process.env.REACT_APP_BACKEND_URL,
//   // baseURL: "http://localhost:3000/v1/",
// });

// // export default axios.create({
// //   baseURL: "https://debugbackend.ddnsfree.com/backend1/v1/"
// // });
