import API from "api2";
import { showAlert } from "actions/templateAction";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};


export const getExamLab = (courseid ,skip, limit) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `/api/course/${courseid}/examLabs`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: 'GET_EXAM_LAB_SUCCESS',
            payload: true,
            data: res.data.data,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'GET_EXAM_LAB_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};
export const updateExams = (courseid,editData) => {
  
  return async (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    let form = {
      exams: editData.exams,
    };

    return new Promise(function (resolve, reject) {
      API.put(`api/exam/${courseid}/examLabs`, form, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_EXAM_SUCCESS', payload: 'success', data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: 'UPDATE_EXAM_FAILED', payload: 'failed', err });
          reject(errorMessage);
        });
    });
  }
};
