import API from "api2";
import { showAlert } from "actions/templateAction";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};
export const getAllQuestionTags = (skip, limit) => {
    return (dispatch, getState) => {
      const token =
        getState()?.authState?.user?.accessToken ||
        localStorage.getItem('accessToken');

      return new Promise((resolve, reject) => {
        API({
          method: 'get',
          url: `api/questionTags?skip=${skip}&limit=${limit}`,
          headers: config(token),
        })
          .then((res) => {
            dispatch({
              type: 'GET_ALL_QUESTION_TAGS_SUCCESS',
              payload: true,
              data: res.data.data,
            });
            resolve();
          })
          .catch((err) => {
            let errMessage =
              (err.response && err.response.data.message) ||
              'Unknown Error Occured';
            dispatch({
              type: 'GET_ALL_QUESTION_TAGS_FAILED',
              payload: 'failed',
              err: errMessage,
            });
            reject();
          });
      });
    };
  };
  export const getQuestion = (skip, limit, categoryId,selectTag) => {
    return (dispatch, getState) => {
      const token =
        getState()?.authState?.user?.accessToken ||
        localStorage.getItem('accessToken');
      API({
        method: 'get',
        url: `api/mcq?skip=${skip}&limit=${limit}&questionCategoryId=${categoryId}&tag=${selectTag}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: 'FETCH_ALL_QUESTIONS',
            payload: true,
            data: res.data.data.list,
          });
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_ALL_QUESTIONS_FAILED',
            payload: 'failed',
            err: errMessage,
          });
        });
    };
  };
  
  