import { createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from "reducers/rootReducer";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['labsReducer'],
};

const presistedReducer = persistReducer(persistConfig, rootReducer );
const store = createStore(presistedReducer, 
applyMiddleware(thunk));
const persistor = persistStore(store);
export { persistor, store };

