import API from "api2";
import { showAlert } from "actions/templateAction";

const config = (accessToken) => {
  return {
    Authorization: accessToken,
  };
};

export const createPlan = (formData) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/license`,
        headers: config(token),
        data: {
          name: formData.name,
          allowedUsersCount: formData.allowedUsersCount,
          type: formData.type,
          validity: formData.validity,
          isCategoryViewRestricted: formData.isCategoryViewRestricted,
          allowedCategories: formData.allowedCategories,
          allowedCourses: formData.allowedCourse,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'CREATE_PLAN_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : 'Unknown error occurred';
          dispatch({ type: 'CREATE_PLAN_ERROR' }, err);
          reject(errorMessage);
        });
    });
  };
};

export const createCoursePlan = (formData) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/license/course/${formData.courseid}`,
        headers: config(token),
        data: {
          name: formData.name,
          allowedUsersCount: formData.allowedUsersCount,
          type: formData.type,
          validity: formData.validity,
          isCategoryViewRestricted: formData.isCategoryViewRestricted,
          allowedCategories: formData.allowedCategories,
          allowedChallenges: formData.allowedChallenges,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'CREATE_PLAN_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : 'Unknown error occurred';
          dispatch({ type: 'CREATE_PLAN_ERROR' }, err);
          reject(errorMessage);
        });
    });
  };
};

export const editPlan = (planId, data) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/license/${planId}`,
        headers: config(token),
        data: {
          type: data.type,
          validity: data.validity,
          name: data.name,
          allowedUsersCount: data.allowedUsersCount,
          isCategoryViewRestricted: data.isCategoryViewRestricted,
          allowedCategories: data.allowedCategories,
          allowedChallenges: data.allowedChallenges,
          allowedCourses: data.allowedCourse,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_PLAN_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          dispatch(getAllPlans(0, 300));
          resolve(data);
        })
        .catch((err) => {
          let errorMessage = err.response.data
            ? err.response.data.message
            : 'Unknown error occurred';
          dispatch({ type: 'UPDATE_PLAN_ERROR' }, err);
          dispatch(showAlert(true, 'warning', errorMessage));
          reject(errorMessage);
        });
    });
  };
};

export const editCoursePlan = (planId, data) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/license/course/${planId}`,
        headers: config(token),
        data: {
          type: data.type,
          validity: data.validity,
          name: data.name,
          allowedUsersCount: data.allowedUsersCount,
          isCategoryViewRestricted: data.isCategoryViewRestricted,
          allowedCategories: data.allowedCategories,
          allowedChallenges: data.allowedChallenges,
          allowedCourses: data.allowedCourses,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_COURSE_PLAN_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          dispatch(getAllPlans(0, 300));
          resolve(data);
        })
        .catch((err) => {
          let errorMessage = err.response.data
            ? err.response.data.message
            : 'Unknown error occurred';
          dispatch({ type: 'UPDATE_COURSE_PLAN_ERROR' }, err);
          dispatch(showAlert(true, 'warning', errorMessage));
          reject(errorMessage);
        });
    });
  };
};

export const editCourseExamLabs = (planId, data) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/course/${planId}/examLabs`,
        headers: config(token),
        data: {
          examChallenges: data.examChallenges,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_COURSE_EXAMS_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          dispatch(getAllPlans(0, 3000));
          resolve(data);
        })
        .catch((err) => {
          let errorMessage = err.response.data
            ? err.response.data.message
            : 'Unknown error occurred';
          dispatch({ type: 'UPDATE_COURSE_EXAMS_ERROR' }, err);
          dispatch(showAlert(true, 'warning', errorMessage));
          reject(errorMessage);
        });
    });
  };
};

export const editExamsExamLabs = (planId, data) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'put',
        url: `api/exam/${planId}/examLabs`,
        headers: config(token),
        data: {
          exams: data.examChallenges,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_COURSE_EXAMS_SUCCESS' });
          dispatch(showAlert(true, 'success', data.message));
          dispatch(getAllPlans(0, 3000));
          resolve(data);
        })
        .catch((err) => {
          let errorMessage = err.response.data
            ? err.response.data.message
            : 'Unknown error occurred';
          dispatch({ type: 'UPDATE_COURSE_EXAMS_ERROR' }, err);
          dispatch(showAlert(true, 'warning', errorMessage));
          reject(errorMessage);
        });
    });
  };
};

export const deletePlan = (planId) => {
  return (dispatch, getState) => {
    const state = getState().plans.plans;
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise((resolve, reject) => {
      API({
        method: 'delete',
        url: `api/license/${planId}`,
        headers: config(token),
      })
        .then(() => {
          let c = state.filter((item) => item._id !== planId);
          dispatch({ type: 'DELETE_PLAN_SUCCESS', data: c });
          resolve();
        })
        .catch((err) => {
          dispatch({ type: 'DELETE_PLAN_ERROR' }, err);
          reject();
        });
    });
  };
};

export const getAllPlans = (skip, limit) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/license?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: 'GET_ALL_PLAN_SUCCESS',
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'GET_ALL_PLAN_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};

export const getAllCoursePlans = (skip, limit) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise((resolve, reject) => {
      API({
        method: 'get',
        url: `api/course/license?skip=${skip}&limit=${limit}`,
        headers: config(token),
      })
        .then((res) => {
          dispatch({
            type: 'GET_ALL_COURSE_PLAN_SUCCESS',
            payload: true,
            data: res.data.data.list,
          });
          resolve();
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'GET_ALL_COURSE_PLAN_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject();
        });
    });
  };
};

export const assignLicenseToOrg = (licenseIds, orgUserId) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/license/assign/organisation`,
        headers: config(token),
        data: {
          licenseIds: licenseIds,
          orgUserId: orgUserId,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'ASSIGNED_LICENSE_TO_ORG_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : 'Unknown error occurred';
          dispatch({ type: 'ASSIGNED_LICENSE_TO_ORG_FAILED' }, err);
          reject(errorMessage);
        });
    });
  };
};

export const assignLicenseToUsers = (licenseIds, userId) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/license/assign/user`,
        headers: config(token),
        data: {
          licenseIds,
          userId,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'ASSIGNED_LICENSE_TO_USER_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : 'Unknown error occurred';
          dispatch({ type: 'ASSIGNED_LICENSE_TO_USER_FAILED' }, err);
          reject(errorMessage);
        });
    });
  };
};

export const assignLicenseToUser = (licenseIds, userId) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/license/assign/user`,
        headers: config(token),
        data: {
          licenseIds,
          userId,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'ASSIGNED_LICENSE_TO_USER_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : 'Unknown error occurred';
          dispatch({ type: 'ASSIGNED_LICENSE_TO_USER_FAILED' }, err);
          reject(errorMessage);
        });
    });
  };
};

export const updateLicenseMany = (user, licenseIds) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    return new Promise(function (resolve, reject) {
      API({
        method: 'post',
        url: `api/license/assign/users`,
        headers: config(token),
        data: {
          licenseIds,
          userIds: user,
        },
      })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'UPDATE_LICENSE_MANY_SUCCESS' });
          resolve(data);
        })
        .catch((err) => {
          let errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : 'Unknown error occurred';
          dispatch({ type: 'UPDATE_LICENSE_MANY_FAILED' }, err);
          reject(errorMessage);
        });
    });
  };
};

export const revokeManyUserLicense = (users) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    let licenseIds = [];
    // for (const user of users) {
    //   if (user.licenseIds.length > 0) {
    //     licenseIds = licenseIds.concat(user.licenseIds.map(x => x._id));
    //   }
    // }
    // let uniqLicenseIds = [...new Set(licenseIds)];
    // const data = {
    //   licenseIds: uniqLicenseIds,
    //   userIds: users?.map(x => x._id)
    // }
    const data = {
      licenseIds: [],
      userIds: users,
    };
    return new Promise((resolve, reject) => {
      API.post('api/license/revoke/users', data, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'REVOKE_LICENSE_SUCCESS', payload: true, data });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'REVOKE_LICENSE_FAILED' }, err);
          reject({ success: false, response: err, message: errMessage });
        });
    });
  };
};

export const disableManyUserSolution = (users) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    let licenseIds = [];
    const data = {
      userIds: users,
    };
    return new Promise((resolve, reject) => {
      API.put('api/users/disableExamMode', data, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'DISABLE_SOLUTION_SUCCESS', payload: true, data });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'DISABLE_SOLUTION_FAILED' }, err);
          reject({ success: false, response: err, message: errMessage });
        });
    });
  };
};

export const enableManyUserSolution = (users) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    let licenseIds = [];
    const data = {
      userIds: users,
    };
    return new Promise((resolve, reject) => {
      API.put('api/users/enableExamMode', data, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({ type: 'ENABLE_SOLUTION_SUCCESS', payload: true, data });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'ENABLE_SOLUTION_FAILED' }, err);
          reject({ success: false, response: err, message: errMessage });
        });
    });
  };
};

export const toggleManyUserStatus = (users, type) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    const data = {
      userIds: users.map((x) => x._id),
    };
    return new Promise((resolve, reject) => {
      API.put(`api/user/${type}`, data, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: 'FETCH_USERS_STATISTICS_SUCCESS',
            payload: true,
            data: res.data.data,
          });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_USERS_STATISTICS_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject({ success: false, message: errMessage });
        });
    });
  };
};
export const toggleManyDelete = (users, type) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    const data = {
      userIds: users.map((x) => x._id),
    };
    return new Promise((resolve, reject) => {
      API.put(`api/user/${type}`, data, { headers: config(token) })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: 'FETCH_USERS_STATISTICS_SUCCESS',
            payload: true,
            data: res.data.data,
          });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({
            type: 'FETCH_USERS_STATISTICS_FAILED',
            payload: 'failed',
            err: errMessage,
          });
          reject({ success: false, message: errMessage });
        });
    });
  };
};

export const enableLicenseActivation = (license) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    const userId = getState()?.authState?.user?._id;
    let data = JSON.stringify({
      userId: userId,
    });
    return new Promise((resolve, reject) => {
      API.put(`api/license/${license._id}/activate`, data, {
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: 'TOGGLE_LICENSE_ACTIVATION_SUCCESS',
            payload: true,
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'TOGGLE_LICENSE_ACTIVATION_FAILED' }, err);
          reject({ success: false, response: err, message: errMessage });
        });
    });
  };
};

export const disableLicenseActivation = (license) => {
  return (dispatch, getState) => {
    const token =
      getState()?.authState?.user?.accessToken ||
      localStorage.getItem('accessToken');
    const userId = getState()?.authState?.user?._id;
    let data = JSON.stringify({
      userId: userId,
    });
    return new Promise((resolve, reject) => {
      API.put(`api/license/${license._id}/deactivate`, data, {
        headers: config(token),
      })
        .then((res) => {
          const { data } = res;
          dispatch({
            type: 'TOGGLE_LICENSE_ACTIVATION_SUCCESS',
            payload: true,
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) ||
            'Unknown Error Occured';
          dispatch({ type: 'TOGGLE_LICENSE_ACTIVATION_FAILED' }, err);
          reject({ success: false, response: err, message: errMessage });
        });
    });
  };
};
